import { template as template_9b4fef75ce4f447594bd9566eb7f369e } from "@ember/template-compiler";
const WelcomeHeader = template_9b4fef75ce4f447594bd9566eb7f369e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
