import { template as template_0e759427e4584d2d830050cffe58465e } from "@ember/template-compiler";
const FKLabel = template_0e759427e4584d2d830050cffe58465e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
