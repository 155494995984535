import { template as template_4a397f1230b247278767c731090b814b } from "@ember/template-compiler";
const FKControlMenuContainer = template_4a397f1230b247278767c731090b814b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
