import { template as template_486746ed9b0842c7aee95aceb7b09396 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_486746ed9b0842c7aee95aceb7b09396(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
