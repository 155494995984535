import { template as template_fcc1e2a503664fe1a5e8205a6ab1203d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_fcc1e2a503664fe1a5e8205a6ab1203d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
